html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

/* ==========================================================================
     Author's custom styles
     ========================================================================== */

body {
  background-image:url('/img/superdome.png'), linear-gradient(
    5deg,
    #FEB31C,#F2F2F2
    
  );
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  font-family: "Bevan", serif;
  font-weight: 400;
  color: #000;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  
  
}

#container {
  padding: 15px;
  margin-bottom: 80px;
  flex:1;
}

#footer {
  margin-top: auto;
  padding: 10px 15px 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.75em;
  font-weight: normal;
  /*border-top: solid 1px #ffffff99;
  */
  margin-left: 15px;
  margin-right: 15px;
  color: #00000099;
  
 
}

#footer a {
  cursor: pointer;
}

#footer a:hover {
  text-decoration: underline;
}

.logo {
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
}

.logo img {
  width: 85%;
  max-width: 350px;
}

.instructions {
  text-align: center;
  font-size: 1.56em;
  font-weight: 1000;
  text-transform: uppercase;
  margin: 20px 0 5px;
  
}

.option {
  padding: 20px 25px;
  margin: 15px 0 8px;
  background-image: linear-gradient(
  #FFAC06,
    #FCBE43
  );
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.3));
  clip-path: polygon(3% 0, 100% 3%, 100% 98%, 2% 100%);
    
}

.option:nth-child(1) {
  padding: 20px 20px;
  margin: 10px 0 8px;
  background-image: linear-gradient(
    #FCBE43,#FFAC06
  );
  clip-path: polygon(0 3%, 98% 0, 100% 98%, 2% 100%);
}

.option .topLine {
  display: flex;
  align-items: center;
  line-height: 1.2;
}
.option .vote {
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: 900;
  background-color: #000;
  padding-top:10px;
  padding-bottom: 10px;
  padding-left:0;
  padding-right:0;
  margin-right: 10px;
  color: #FFF;
  box-shadow: #00000033 3px 3px 3px;
  cursor: pointer;
  max-width: 0;
  overflow: hidden;
}

.novote .option .vote {
  max-width: 150px;
  border: solid 1px #000;
  padding-left: 10px;
  padding-right: 10px;
}

.options .vote:hover {
  background: #FFF;
  color: #000;
  box-shadow: #00000099 1px 1px 1px;
}

.options .vote:active {
  background: #FFF;
  color: #000;
  box-shadow: inset #000000CC 1px 1px 1px;
}

.option .header {
  font-weight: 900;
  font-size: 1.5em;
  text-transform: uppercase;
}

.option .subhead {
  font-size: 1.1em;
}

.option .thumbnail img {
  width: 100%;
  margin-top: 10px;
}

.terms {
  display: none;
  color: #ffffff99;
}

.terms.show {
  display: block;
}


.voted {
  max-width: 0;
  overflow: hidden;
}

/* CHECKMARK */
.checkmark__circle {
  stroke-dasharray: 216; /* ORIGINALLY 166px */
  stroke-dashoffset: 216; /* ORIGINALLY 166px */
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
}

.checkmark {
  width: 45px; /* ORIGINALLY 56px */
  height: 45px; /* ORIGINALLY 56px */
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 98; /* ORIGINALLY 48px */
  stroke-dashoffset: 98; /* ORIGINALLY 48px*/
}

.options.done .yes .voted .checkmark__circle {
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.options.done .yes .voted .checkmark {
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.options.done .yes .voted .checkmark__check {
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.options.done .vote {
  width:0;
  overflow: hidden;
  transition: all 0.3s;
  padding-left:0;
  padding-right:0;
  border:0;
}

.options.done .yes .voted {
  max-width: 200px;
  overflow: hidden;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 80px #7ac142;
  }
}

/* ==========================================================================
     Helper classes
     ========================================================================== */

.hidden,
[hidden] {
  display: none !important;
}

.visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  /* 1 */
}

.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix::before,
.clearfix::after {
  content: "";
  display: table;
}

.clearfix::after {
  clear: both;
}

@media only screen and (min-width: 1024px) {
  body {
    font-size: 1.25em;
  }
  #container {
    max-width: 1200px;
    margin: 0 auto;
    background-size: 500px;
  }
  .instructions {
    margin-top: 20px;
  }
  .options {
    display: flex;
    margin-top: 10px;
    justify-content: center;
  }

  .options .option {
    margin: 10px;
    box-shadow: #00000033 3px 3px 3px;
  }

  #terms {
    max-width: 900px;
  }
}
